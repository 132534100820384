module.exports = {
  current: {},
  init: function (id, value) {
    if (this.current[id] == undefined) {
      this.current[id] = value;
      return true;
    }
    return false;
  },
  next: function (id) {
    if (mutex.lock(id, 2500)) {
      this.hide(id, this.current[id]);
      this.show(id, this.current[id] + 1);
    }
  },
  prev: function (id) {
    if (mutex.lock(id, 2500)) {
      this.hide(id, this.current[id]);
      this.show(id, this.current[id] - 1);
    }
  },
  hide: function (id, index) {
    mutex.lock(id, 2500); // just in case, should be already locked
    var elems = Array.from(document.querySelectorAll(id + ' .gallery_1_slide'));
    elems[index].querySelector('.content .wrapper').style.height = 0;
    setTimeout(function() {
      elems[index].style.opacity = 0;
      setTimeout(function() {
        elems[index].style.display = 'none';
      }, 2000);
    }, 250);
  },
  show: function (id, index) {
    mutex.lock(id, 1500); // just in case, should be already locked, except at first load
    var elems = Array.from(document.querySelectorAll(id + ' .gallery_1_slide'));
    if (index < 0) {
      index = elems.length - 1;
    }
    if (index > elems.length - 1) {
      index = 0;
    }
    elems[index].style.display = 'block';
    setTimeout(function() {
      elems[index].style.opacity = 1;
      setTimeout(function() {
        elems[index].querySelector('.content .wrapper').style.height = window.getComputedStyle(elems[index].querySelector('.content .wrapper .menu')).height;
      }, 750);
    }, 500);
    document.querySelector(id + ' .current').textContent = helpers.leadingZero(index + 1);
    this.current[id] = index;
  }
};
