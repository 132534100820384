module.exports = {
  duration: 0,
  animateLeft: function(id) {
    this.duration = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) * 100;
    anime.set(id + ' .left', { translateX: '0vw' });
    anime({
      targets: id + ' .left',
      translateX: '-= 120vw',
      duration: this.duration,
      easing: 'linear',
      complete: function(anim) {
        anime.set(id + ' .left', { translateX: '0vw' });
        gallery_2.animateLeft(id);
      }
    });
  },
  animateRight: function(id) {
    anime.set(id + ' .right', { translateX: '-140vw' });
    anime({
      targets: id + ' .right',
      translateX: '+= 120vw',
      duration: this.duration,
      easing: 'linear',
      complete: function(anim) {
        anime.set(id + ' .right', { translateX: '-140vw' });
        gallery_2.animateRight(id);
      }
    });
  },
  animate: function(id) {
    this.animateLeft(id);
    this.animateRight(id);
  }
}
