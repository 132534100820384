document.addEventListener('turbolinks:load', function() {
  window.scrollTo(0, 0);
  intro();
  setup();

  if (!localStorage.getItem('accept_cookies')) {
    document.querySelector('#cookie_consent').style.display = 'block';
    anime({
      targets: '#cookie_consent',
      opacity: 1,
      duration: 1000,
      easing: 'linear'
    });
  }

  document.querySelector('#accept_button .button').addEventListener('click', function() {
    localStorage.setItem('accept_cookies', true);
    anime({
      targets: '#cookie_consent',
      opacity: 0,
      duration: 1000,
      easing: 'linear',
      complete: function() {
        document.querySelector('#cookie_consent').style.display = 'none';
      }
    });
  });
});
