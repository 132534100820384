// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require('hamburger')
require('onload')
require('onresize')
scrollto = require('scrollto')
anime = require('anime.min')
mutex = require('mutex')
helpers = require('helpers')
banner_1 = require('banner_1')
gallery_1 = require('gallery_1')
gallery_2 = require('gallery_2')
gallery_3 = require('gallery_3')

// animation stuff
lottie = require('lottie')
anim_in = require('anim_in')
anim_out = require('anim_out')
intro = require('intro')

// contact
mailer = require('mailer')
