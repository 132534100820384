module.exports = {
  current: {},
  next: function(id) {
    this.transition(id, this.current[id] == undefined ? 0 : this.current[id] + 1);
  },
  prev: function(id) {
    this.transition(id, this.current[id] == undefined ? 0 : this.current[id] - 1);
  },
  show: function(id, index) {
    if (mutex.lock(id, 500)) {
      document.querySelector(id + ' .image-container').style.backgroundImage = 'none';
      document.querySelector(id + ' .overlay').classList.add('displayed');
      setTimeout(function() {
        document.querySelector(id + ' .overlay').classList.add('visible');
        mutex.release(id);
        gallery_3.transition(id, index);
      }, 100);
    }
  },
  hide: function (id) {
    if (mutex.lock(id, 500)) {
      document.querySelector(id + ' .overlay').classList.remove('visible');
      setTimeout(function() {
        setTimeout(function () {
          document.querySelector(id + ' .overlay').classList.remove('displayed');
          mutex.release(id);
        }, 100);
      }, 500);
    }
  },
  transition: function (id, index) {
    if (mutex.lock(id)) {
      var imageCount = Array.from(document.querySelectorAll(id + ' .picture')).length;
      if (index < 0) {
        index = imageCount - 1;
      }
      if (index >= imageCount) {
        index = 0;
      }
      this.current[id] = index;
      document.querySelector(id + ' .loader-container').classList.add('displayed');
      document.querySelector(id + ' .loader-container').classList.add('visible');
      var img = new Image();
      img.addEventListener('load', e => {
        setTimeout(function() {
          document.querySelector(id + ' .image-container').style.backgroundImage = 'url("' + img.src + '")';
          document.querySelector(id + ' .loader-container').classList.remove('visible');
          setTimeout(function() {
            document.querySelector(id + ' .loader-container').classList.remove('displayed');
            mutex.release(id);
          }, 500);
        }, 500);
      });
      img.src = Array.from(document.querySelectorAll(id + ' .picture'))[index].querySelector('.frame').dataset.url;
    }
  },
  init: function (id) {
    Array.from(document.querySelectorAll(id + ' .picture')).forEach((p, i) => {
      p.addEventListener('click', e => gallery_3.show(id, i));
    });
  }
}
