module.exports = {
  mutexes: {},
  lock: function(id, timeout) {
    if (this.mutexes[id]) {
      return false;
    }
    this.mutexes[id] = true;
    if (timeout) {
      setTimeout(function() {
        mutex.release(id);
      }, timeout);
    }
    return true;
  },
  release: function(id) {
    this.mutexes[id] = false;
  }
}
