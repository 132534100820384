module.exports = {
  timeout: null,
  current_slide: 0,
  initMenu: function() {
    Array.from(document.querySelectorAll('.banner_1 .navbar-item')).forEach(item => {
      if (item.nextElementSibling && (item.nextElementSibling.classList.contains('navbar-dropdown') || item.nextElementSibling.classList.contains('dropdown-menu'))) {
        item.addEventListener('click', e => {
          var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
          if (w <= 800 && item.nextElementSibling.classList.contains('dropdown-menu')) {
            return true; // we dont handle this one on mobile, let's load category page
          } else {
            e.preventDefault();
            banner_1.toggleMenuItem(item.nextElementSibling);
            return false;
          }
        });
      }
    });
  },
  toggleMenu: function() {
    if (mutex.lock('banner_1_menu', 1000)) {
      var elem = document.querySelector('.banner_1 .menu');
      var style = getComputedStyle(elem);
      if (style.width == '0px') {
        elem.style.width = '350px';
        setTimeout(function() {
          elem.style.overflow = 'visible';
        }, 1000);
      } else {
        elem.style.overflow = 'hidden';
        elem.style.width = '0px';
      }
    }
  },
  toggleMenuItem: function(el) {
    if (el.classList.contains('dropdown-menu')) {
      Array.from(document.querySelectorAll('.banner_1 .dropdown-menu')).forEach(elem => {
        if (elem != el) elem.classList.remove('menu-visible');
      });
    }
    if (el.classList.contains('navbar-dropdown')) {
      Array.from(document.querySelectorAll('.banner_1 .navbar-dropdown')).forEach(elem => {
        if (elem != el) elem.classList.remove('menu-visible');
      });
      Array.from(document.querySelectorAll('.banner_1 .dropdown-menu')).forEach(elem => {
        if (elem != el) elem.classList.remove('menu-visible');
      });
    }
    if (el.classList.contains('menu-visible')) {
      el.classList.remove('menu-visible');
    } else {
      el.classList.add('menu-visible');
    }
  },
  toggleView: function(e) {
    e.stopPropagation();
    var elem = document.querySelector('.banner_1 .wrapper');
    if (elem.classList.contains('gallery_view')) {
      elem.classList.remove('gallery_view');
    } else {
      elem.classList.add('gallery_view');
    }
  },
  showSlide: function(e, slide_index, first_load) {
    if (document.querySelector('.banner_1') == null) {
      return false;
    }
    if (mutex.lock('banner_1', 2000)) {
      clearTimeout(this.timeout);
      if (e) {
        e.stopPropagation();
      }
      // set correct index
      if (slide_index < 0) slide_index = document.querySelectorAll('.banner_1 .banner_1_slide').length - 1;
      if (slide_index >= document.querySelectorAll('.banner_1 .banner_1_slide').length) slide_index = 0;
      // fadeout elements on the left after each other
      Array.from(document.querySelectorAll('.banner_1 .banner_1_slide')[this.current_slide].querySelectorAll('.hider')).forEach((e, i) => {
        setTimeout(function() {
          e.classList.remove('hidden');
        }, i * 50);
      });
      Array.from(document.querySelectorAll('.banner_1 .banner_1_slide')[slide_index].querySelectorAll('.hider')).forEach((e, i) => {
        e.classList.remove('hidden');
      });
      if (first_load) {
        // slide in picture from the right
        document.querySelectorAll('.banner_1 .banner_1_slide')[slide_index].classList.add('displayed');
        setTimeout(function() {
          Array.from(document.querySelectorAll('.banner_1 .picture')).forEach(e => e.classList.add('displayed'));
          Array.from(document.querySelectorAll('.banner_1 .picture')).forEach(e => e.classList.add('visible'));
        }, 1500);
      } else {
        // fadeout picture on the right
        Array.from(document.querySelectorAll('.banner_1 .picture')).forEach(e => e.classList.remove('visible'));
      }
      setTimeout(function() {
        // fadeout all the slides
        if (!first_load) {
          Array.from(document.querySelectorAll('.banner_1 .banner_1_slide')).forEach(e => e.classList.remove('visible'));
        }
        setTimeout(function() {
          // set required slider to display = block
          Array.from(document.querySelectorAll('.banner_1 .banner_1_slide')).forEach((e, i) => {
            if (i == slide_index) e.classList.add('displayed');
            else e.classList.remove('displayed');
          });
          setTimeout(function() {
            // fadein images, make hiders 0px
            Array.from(document.querySelectorAll('.banner_1 .picture')).forEach(e => e.classList.add('visible'));
            Array.from(document.querySelectorAll('.banner_1 .hider')).forEach(e => e.classList.add('hidden'));
            // size image to fill the area below the text in mobile view
            var bh = document.querySelector('.banner_1').offsetHeight;
            var th = document.querySelectorAll('.banner_1 .text')[slide_index].offsetHeight;
            document.querySelectorAll('.banner_1 .picture')[slide_index].style.minHeight = (bh - th) + 'px';
            // fadein slider
            document.querySelectorAll('.banner_1 .banner_1_slide')[slide_index].classList.add('visible');
            banner_1.current_slide = slide_index;
            banner_1.timeout = setTimeout(function() {banner_1.showSlide(null, slide_index + 1)}, 7000);
          }, 50);
        }, first_load ? 0 : 1000)
      }, 500);
    }
  }
}
