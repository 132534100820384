module.exports = function() {
  var container = document.querySelector('#lottie');
  if (container) {
    container.style.display = 'block';
    var params = {
      container: document.getElementById('animation'),
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: anim_in,
    };
    lottie.loadAnimation(params).addEventListener('complete', function() {
      var params = {
        container: document.getElementById('animation'),
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: anim_out,
      };
      setTimeout(function() {
        lottie.destroy();
        lottie.loadAnimation(params).addEventListener('complete', function() {
          container.style.opacity = 0;
          setTimeout(function() {
            container.style.display = 'none';
            banner_1.showSlide(null, 0, true);
          }, 1000);
        });
      }, 100)
    });
  }
};
