module.exports = {
  send: function(event, form) {
    event.preventDefault();

    Array.from(document.querySelectorAll('.notification .delete')).forEach((e) => {
      e.parentNode.style.display = 'none';
    });

    fetch('/contact', {
      method: 'POST',
      body: new FormData(form)
    }).then(function(response) {
      if (response.status == 200) {
        form.querySelector('.is-success').style.display = 'block';
        form.querySelector('.is-success').scrollIntoView({behavior: 'smooth'});
        form.reset();
      } else {
        form.querySelector('.is-danger').style.display = 'block';
        form.querySelector('.is-danger').scrollIntoView({behavior: 'smooth'});
      }
    });
  }
}
